<template>
  <div class="_404">
    <div id="text-shadow-box" :style="'background-image: '+getImagem()+'; top: -10%'">
      <div style="box-shadow: black 0px 34.4px 38px;" id="tsb-box"></div>
      <p style="text-shadow: black 24.8px 34.4px 18px;" id="tsb-text">Página não encontrada</p>
      <p id="tsb-link">Página não encontrada</p>
      <div style="background-position: -62px -212px;" id="tsb-spot"></div>
    </div>
  </div>
</template>
<script async>
  import img1 from '../assets/img/not_found/not_found (1).jpg'
  import img2 from '../assets/img/not_found/not_found (2).jpg'
  import img3 from '../assets/img/not_found/not_found (3).jpg'
  import img5 from '../assets/img/not_found/not_found (5).jpg'
  import img6 from '../assets/img/not_found/not_found (6).jpg'
  export default {

    data: () => ({
      x: 100,
      y: 100,
      imgs: [img1,img2,img3,img5,img6]
    }),
    methods:{
      getImagem(){
        var sorteada = Math.floor(Math.random() * this.imgs.length);
        return  "url('"+this.imgs[sorteada]+"')"
      }
    },
    mounted() {
      var _this = this
      var text = null
      var spot = null
      var box = null
      var text_shadow_box = null
      var boxProperty = ''
      init()



      function init() {
        text = document.getElementById('tsb-text')
        spot = document.getElementById('tsb-spot')
        box = document.getElementById('tsb-box')


        //
        if (typeof box.style.webkitBoxShadow == 'string') {
          boxProperty = 'webkitBoxShadow'
        } else if (typeof box.style.MozBoxShadow == 'string') {
          boxProperty = 'MozBoxShadow'
        } else if (typeof box.style.boxShadow == 'string') {
          boxProperty = 'boxShadow'
        }
        if (text && spot && box) {
          document.getElementById('text-shadow-box').onmousemove = onMouseMove
          document.getElementById('text-shadow-box').ontouchmove = function(e) {
            e.preventDefault()
            e.stopPropagation()
            onMouseMove({
              clientX: e.touches[0].clientX,
              clientY: e.touches[0].clientY
            })
          }
        }
      }

      function onMouseMove(e) {
        if (typeof e === 'undefined' || typeof e.clientX === 'undefined') {
          return
        }
        var xm = (e.clientX - Math.floor(window.innerWidth / 2)) * 0.4
        var ym = (e.clientY - Math.floor(window.innerHeight / 3)) * 0.4
        var d = Math.round(Math.sqrt(xm * xm + ym * ym) / 5)
        text.style.textShadow = -xm + 'px ' + -ym + 'px ' + (d + 1) + 'px black'
        if (boxProperty) {
          box.style[boxProperty] = '0 ' + -ym + 'px ' + (d + 30) + 'px black'
        }
        xm = e.clientX - Math.floor(window.innerWidth / 2)
        ym = e.clientY - Math.floor(window.innerHeight / 2)
        var total = 400;

        if (xm < 0){
          total = (window.innerHeight-100) + (xm)
        } else {
          total = (window.innerHeight-100) - (xm)
        }
        total = total > 300 ? 300 : (total < 150 ? 150 : total)

        spot.style.background = "-webkit-gradient(radial, center center, 0, center center,"+ total+", from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.95)))"
        spot.style.backgroundPosition = xm + 'px ' + ym + 'px'

      }
    }

  }
</script>
<style scoped>

  #text-shadow-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat:no-repeat;
    background-color: transparent;
    background-size: 100% 100%;
    font-family: Rockwell, Arial, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
  }

  #text-shadow-box #tsb-text, #text-shadow-box #tsb-link {
    position: absolute;
    color: #FFFFFF;
    top: 40%;
    left: 0;
    width: 100%;
    height: 1em;
    margin: -0.77em 0 0 0;
    font-size: 70px;
    line-height: 1em;
    font-weight: bold;
    text-align: center;
  }

  #text-shadow-box #tsb-text {
    font-size: 100px;
    color: transparent;
    text-shadow: black 0px -45.2px 19px;
  }

  #text-shadow-box  {
    color: #FFFFFF;
    text-decoration: none;
  }

  #tsb-spot {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    pointer-events: none;
    background: -webkit-gradient(radial, center center, 0, center center, 300, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.9)));
    background: -moz-radial-gradient(center 45deg, circle closest-side, transparent 0, rgba(0, 0, 0, 0.5) 200px);

  }
</style>
